footer {
	padding:42px 0;
	@include tablet {
		display:none;		
	}
	p {
		color:var(--grey);
		font-size:14px;
		font-weight:500;
		a {
			color:inherit;
			margin-left:20px;
		}
	}
}