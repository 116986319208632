.page-transition {
	position: fixed;
	top:0;
	left:0;
	width:$p1;
	height:$p1;
	will-change: transform;
	@include transform(translateY($p1));
	&.run {
		@include transform(translateY(0));
		@include transition(transform .8s $easeInOutQuart);
	}
	&.run-up {
		height:0;
		@include transform(translateY(0));
		@include transition(height .8s $easeInOutQuart);
	}
}