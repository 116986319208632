// header
header {
	@include flex;
	justify-content: space-between;
	align-items:flex-end;
	padding:20px 42px;
	position: fixed;
	width:$p1;
	z-index:999;
	&:after {
		@include tablet {
			content:'';
			position:absolute;
			background:var(--white);
			top:0;
			left:0;
			height:0;
			width:$p1;
			z-index:-1;
			@include transition(height .4s ease);			
		}
	}
	&.sticky:not(.open) {
		position: fixed;
		width:$p1;
		&:after {
			height:$p1;
		}
	}
	@include midtab {
		padding:16px 24px;	
	}
	@include mobi {
		padding:12px 18px;		
	}
	.logo {
		align-items:flex-end;
		@include flex;
		img,svg {
			width:200px;
		}
		svg {
			.st1 {
				fill:var(--green);
			}
			.st0 {
				fill:var(--blue);
				will-change: fill;
				@include transition(fill .3s .2s);
			}
		}
		&.white {
			svg {
				.st0 {
					fill:var(--white);
				}
			}
		}
	}
	.back-nav {
		a {
			opacity:0;
			@include transition(color .3s .2s,opacity .3s .4s);
			&.show {
				opacity: 1;
			}
		}
		@include tablet {
			display:none;		
		}
	}
	nav {
		font-size:30px;
		line-height: 39px;
		font-weight:400;
		color:var(--green);
		@include flex;
		@include mid {
			font-size:24px;	
			a.magnet > span svg {
				width:17px;
				height:17px;
			}
		}
		@include tablet {
			display:none;		
		}
		a {
			letter-spacing: -1px;
			color:currentColor;
			text-transform: uppercase;
		}
		.slash {
			margin:0 42px;
			color:currentColor;
			@include midtab {
				margin:0 16px;
			}
		}
	}
	.menu-icon {
		display:none;
		width:120px;
		position: relative;
		top:-11px;
		height:18px;
		@include transition(height .3s);
		@include tablet {
			display:block;		
		}
		@include mobi {
			width:100px;		
		}
		span {
			display:block;
			width:$p1;
			height:4px;
			background:var(--blue);
			position: absolute;
			top:0;
			@include transition(background .3s);
			&:last-child {
				top:$p1;
			}
		}
		p {
			position: absolute;
			top:$p2;
			right:0;
			text-align: right;
			opacity:0;
			line-height:1;
			@include transform(translateY(-$p2));
			@include transition(opacity .3s,transform .3s);
		}
		&.menu-white {
			span {
				background:var(--white);
			}
		}
	}
	&.open {
		.logo {
			p {
				color:var(--white);
			}
		}
		.menu-icon {
			height:28px;
			span {
				background:var(--blue);
				&:last-child {
					background:var(--white);
				}
			}
			p {
				opacity:1;
				// @include transform(translateY(0));
			}
		}
	}
}
.mobi-header {
	display:none;
	background:var(--blue);
	position:fixed;
	width:100vw;
	height:100vh;
	top:0;
	left:0;
	z-index:99;
	@include transform(translateY(-100%));
	@include transition(transform .8s ease-in-out);
	padding:80px 18px;		
	@include tablet {
		@include flex;
		flex-direction: column;
		justify-content:center;
	}
	&.open {
		@include transform(translateY(0));
		nav {
			.number {
				opacity:1;
				@include transform(translateY(0));
				@include transition(opacity .6s .8s, transform .6s .8s);
			}
			a {
				opacity:1;
				@include transform(translateY(0));
				@include transition(opacity .6s .8s, transform .6s .8s);
			}
		}
		footer {
			p {
				opacity:1;
				@include transform(translateY(0));
				@include transition(opacity .6s .8s, transform .6s .8s);
			}
		}
	}
	nav {
		color:var(--green);
		font-size:36px;
		text-transform: uppercase;
		.number {
			color:var(--white);
			font-size:18px;
			margin:0 0 18px;
			opacity:0;
			@include transform(translateY($p2));
			@include transition(opacity .6s, transform .6s);
		}
		a {
			margin-bottom:24px;
			opacity:0;
			@include transform(translateY($p2));
			@include transition(opacity .6s, transform .6s);
		}
		a.magnet > span svg {
			width:25px;
			height:25px;
		}
	}
	footer {
		display:block;
		padding-bottom:0;
		p {
			line-height:1.5;
			opacity:0;
			@include transform(translateY($p2));
			a {
				margin-left:0;
			}
		}
	}
}