// font
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');

// elements
@import 'prefix';
@import 'variables';
@import 'header';
@import 'footer';

// plugins
@import 'lettering';
@import 'magnet';
@import 'imagehover';
@import 'transition';

html {
	margin:0 auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	body {
		@extend html;
		line-height:1.2;
		color:var(--dark);
		font-weight: 400;
		font-family: 'IBM Plex Sans', sans-serif;
	}
}

:root {
	--header-height:85px;
}
* {
	cursor:none!important;
	box-sizing:border-box;
	@include tablet {
		cursor: default!important;		
	}
}

a {
	text-decoration: none;
	color:inherit;
}

img {
	display:block;
	&.img-reveal {
		opacity:0;
		&.down {
			@include transform(translateY(50px));
		}
		&.up {
			@include transform(translateY(-50px));
			@include transition(opacity 1s .3s $easeInQuart,transform 1s .3s $easeInQuart);
		}
		&.in {
			opacity:1;
			@include transform(translateY(0));
			@include transition(opacity 1s .4s $easeOutQuart,transform 1s .4s $easeOutQuart);
		}
	} 
}

// text
h1,h2,h3,h4,h5,h6,p {
	font-weight:400;
	margin:0;
}

// layout
.container {
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	width:$p1;
	padding:0;
	@include tablet {
		min-height: unset;
		height:100vh;
		height: calc(var(--vh, 1vh) * 100);		
	}
}
section {
	width:$p1;
	flex-basis: $p1;
	height: $p1;
	flex-grow: 1;
	@include flex;
	flex-direction: column;
	justify-content: center;
	margin-top:85px;
	margin-top:var(--header);
	&#about {
		justify-content: flex-start;
		height:auto;
	}
	&.rel {
		position: relative;
	}
	&#about,&#contact,&#thanks {
		@include tablet {
			background:var(--white);		
		}
	}
}
.contained {
	width:50%;
	max-width:700px;
	margin:0 auto;
	@include tablet {
		width:$p1;
		margin-right:0;
		padding:0 24px;
	}
}
#welcome,#thanks {
	.contained {
		@include tablet {
			width:75%;
			margin-right:0;
			padding:0;
			padding-right:26px;
		}	
	}
}

// modules
.breadcrumb {
	&.large-gap {
		margin-bottom:100px;
		@include mid {
			margin-bottom:40px;		
		}
		@include tablet {
			margin-bottom:24px;		
		}
	}
	p {
		font-size:20px;
		@include tablet {
			font-size:16px;		
		}
	}
}
.intro-text {
	font-size:30px;
	line-height:1;
	position: relative;
	@include tablet {
		font-size:22px;		
	}
	a {
		color:var(--green);
		margin:38px 0;
	}
}
.contact-text {
	font-size:30px;
	line-height:1;
	a {
		color:var(--green);
		margin:0;
	}
}
.contact-link {
	font-size:30px;
	@include desktop {
		font-size:26px;		
	}
	@include tablet {
		font-size:22px;		
	}
}
.contact-link-small {
	font-size:14px;
	line-height:1.5;
	margin-top:26px!important;
}

// form
.form {
	padding:10vh 0;
	form {
		margin:36px 0;
		.form-group {
			position: relative;
			.input-group {
				position: relative;
				p {
					position: absolute;
					top:$p2;
					font-size: 20px;
					color: var(--white);
					opacity:1;
					visibility: visible;
					will-change: transform,opacity;
					@include transform(translateX(0) translateY(-$p2));
					@include transition(transform .2s ease-in-out,opacity .2s ease-in-out,visibility .2s ease-in-out);
					transition-delay: var(--delay-input);
					@include tablet {
						font-size:16px;		
					}
				}
			}
			label {
				display:block;
				font-size: 20px;
				color: var(--white);
				opacity:0;
				visibility: hidden;
				will-change: transform,opacity,visibility;
				@include transform(translateX(-20px));
				@include transition(transform .2s ease-in-out,opacity .2s ease-in-out,visibility .2s ease-in-out);
				@include tablet {
					font-size:16px;		
				}
			}
			input,select{
				@include appearance;
				border: 0;
				width: $p1;
				background:transparent;
				font-size: 20px;
				padding: 14px 0;
				color: var(--white);
				outline:0!important;
				&::-webkit-input-placeholder {
				  color: inherit;
				}
				&::-moz-placeholder {
				  color: inherit;
				}
				&:-ms-input-placeholder {
				  color: inherit;
				}
				&:-moz-placeholder {
				  color: inherit;
				}
				@include tablet {
					font-size:16px;		
				}
			}
			.line {
				position: absolute;
				top:$p1;
				left:0;
				width:$p1;
				height:3px;
				background:var(--white);
				will-change: width;
				@include transition(width .3s ease-in-out);
				transition-delay: var(--delay-line);
				.line-fill {
					background:var(--green);
					width:0;
					height:$p1;
					position: absolute;
					left:0;
					top:0;
					@include transition(width .3s ease-in-out);
				}
			}
			&:hover,&.filled {
				.input-group {
					p {
						opacity:0;
						visibility: hidden;
						@include transform(translateX(20px) translateY(-$p2));
						@include transition(transform .2s ease-in-out,opacity .2s ease-in-out,visibility .2s ease-in-out);
					}
					input,select {
						opacity:1;
						visibility: visible;
						@include transition(opacity .2s ease-in-out,visibility .2s ease-in-out);
						transition-delay: var(--delay-input);
					}
				}
				label {
					opacity:1;
					visibility: visible;
					@include transform(translateX(0));
					@include transition(transform .2s .2s ease-in-out,opacity .2s .2s ease-in-out,visibility .2s .2s ease-in-out);
					transition-delay: var(--delay-input);
				}
				.line {
					.line-fill {
						width:$p1;
					}
				}
			}
			&.down,&.up {
				.input-group {
					p {
						opacity:0;
						visibility: hidden;
						@include transform(translateX(-20px) translateY(-$p2));
						transition-delay: var(--delay-input);
					}
					input,select {
						opacity:0;
						visibility: hidden;
						@include transition(opacity .2s ease-in-out,visibility .2s ease-in-out);
						transition-delay: var(--delay-input);
					}
				}
				label {
					opacity:0;
					visibility: hidden;
					transition-delay: var(--delay-input);
				}
				.line {
					width:0;
				}
			}
		}	
	}
	.submit-group {
		margin-top:24px;
		&.dual {
			@include flex;
			&.wrap {
				@include tablet {
					flex-wrap:wrap;
					.magnet-link + .magnet-link {
						margin-top:20px;
					}		
				}
			} 
			a {
				margin-right:60px;
			}
		}
		a {
			@include appearance;
			border: 0;
			background:transparent;
			font-size: 60px;
			line-height: 1;
			color:var(--green);
			// border-bottom:4px solid var(--green);
			margin:0;
			padding:0;
			&:after {
				height:3px;
			}
			@include tablet {
				font-size:32px;		
				&:after {
					height:2px;
				}
			}
		}
		&.small {
			a {
				font-size:30px;
				@include tablet {
					font-size:24px;		
				}
			}
		}
		p.input-err {
			position: absolute;
			font-size: 20px;
			color: var(--white);
			margin: 20px 0;
			display: inline-block;
			border-radius: 14px;
			padding: 0;
			// -webkit-font-smoothing: auto;
			// -moz-osx-font-smoothing: grayscale;
			opacity:0;
			@include transform(translateX(-20px));
			will-change: opacity,transform;
			@include transition(opacity .2s,transform .2s);
			&.show {
				opacity:1;
				@include transform(translateX(0));
			}
		}
	}
}



// text transforms
.indent {
	text-indent:14%;
}
.underline {
	text-decoration: underline;
}
.uppercase {
	text-transform: uppercase;
}

// text colors

.text-white {
	color:var(--white);
}
.text-green {
	color:var(--green);
}
.text-blue {
	color:var(--blue);
}

// text size

.big-link {
	font-size: 60px;
}
.medium-text {
	font-size:42px;
	letter-spacing: -1px;
	line-height:1.4;
	&.shortline {
		line-height:1;
	}
	@include tablet {
		font-size:36px;		
	}
}
.large-text {
	font-size:60px;
	letter-spacing: -1px;
	line-height:1;
	@include tablet {
		font-size:36px;		
	}
}
.larger-text {
	font-size:7vw;
	letter-spacing:-0.065em;
	line-height:1;
	@include tablet {
		font-size:4em;		
	}
	span.ch.indent {
		text-indent: 4vw;
		@include tablet {
			text-indent: 0;		
		}
	}
}
#contact {
	.larger-text {
		@include tablet {
			font-size:44px;		
		}
	}
}
.massive-text {
	font-size:22vw;
	letter-spacing: -2vw;
	padding-right:4vw;
	line-height:1;
	@include tablet {
		font-size:45vw;		
		letter-spacing: -4vw;
	}
}
.nowrap {
	white-space: nowrap;
}

// bg color

.bg-white {
	background:var(--white);
}
.bg-green {
	background:var(--green);
}
.bg-blue {
	background:var(--blue);
}

.max-width {
	max-width:700px;
}

// ticker
.ticker-holder {
	position: relative;
	left:25%;
}
section > .ticker {
	@include tablet {
		margin:32px 0;		
	}
}

// grid
.content-grid {
	display:grid;
	grid-template-columns:1fr 1fr;
	width:$p1;
	max-width:unset;
	padding:100px 120px;
	margin:0 auto;
	&.fixed {
		height:calc(100vh - var(--header-height));
		max-height:860px;
		@include tablet {
			max-height: unset;
			height:auto;		
		}
	}
	&.short-bottom {
		padding-bottom:40px;
	}
	&.short-top {
		padding-top:0;
	}
	&.big-padding {
		padding:150px 120px;
	} 
	&.no-padding {
		padding-left:0;
		padding-right:0;
		.grid-item {
			margin-right:0;
		}
	}
	&.solo {
		grid-template-columns:1fr;
	}
	@include tablet {
		grid-template-columns:1fr;		
		padding:30px 24px!important;
	}
	.grid-item {
		margin-right:20px;
		& + .grid-item {
			margin-left:20px;
			margin-right:0;	
			@include tablet {
				margin-left:0;		
			}
		}
		@include tablet {
			margin-right:0;		
		}
		&.grid-img1 {
			img {
				max-width:360px;
				margin:auto;
			}
		}
		&.grid-img2 {
			img {
				max-width:780px;
				margin:auto;
			}
		}
		&.grid-largetext {
			.larger-text {
				@include tablet {
					font-size:32px;
					margin-bottom:14px;		
				}
			}
		}
		&.grid-bottom {
			@include flex;
			flex-direction: column;
			justify-content: flex-end;
			@include tablet {
				margin-top:24px;		
			}
		}
		&.grid-list {
			.gl-item {
				@include flex;
				width:$p1;
				padding-right:20%;
				@include tablet {
					padding-right:6%;		
				}
				& + .gl-item {
					margin-top:50px;
				}
				&.hidden {
					opacity:0;
					visibility: hidden;
					@include tablet {
						display:none;		
					}
				}
				.gl-number {
					margin:10px 36px 0 0;
				}
				.gl-text {
					font-size:30px;
					font-weight:bold;
					@include tablet {
						font-size:18px;		
					}
				}
				.gl-fineprint {
					font-size:16px;
					@include tablet {
						br {
							display:none;
						}		
					}
				}
			}
			&.inset {
				@include transform(translateX(-10%));
				@include tablet {
					@include transform(none);
				}
			}
		}
		img {
			width:$p1;
		}
	}
}

// text pitch 
.text-pitch {
	.letter-carousel {
		position: relative;
		.ab-letter {
			position: absolute;
			top:$p2;
			@include transform(translateY(-$p2));
		}
	}
	.skip {
		position:absolute;
		bottom:0;
		margin-bottom:40px;
		a {
			@include appearance;
			border: 0;
			background:transparent;
			font-size: 40px;
			line-height: 1;
			color:var(--green);
			border-bottom:4px solid var(--green);
			margin:0;
			padding:0;
			@include tablet {
				font-size:32px;		
			}
		}
		@include tablet {
			bottom:60px;		
		}
	}
}

// mouse
.cursor {
	@include tablet {
		display:none;		
	}
	.follow {
		z-index: 9999;
		display: block;
		position: fixed;
		border-top-left-radius: $p2;
		border-top-right-radius: $p2;
		border-bottom-right-radius: $p2;
		border-bottom-left-radius: $p2;
		width: 18px;
		height: 18px;
		pointer-events: none;
		background-color: rgb(20, 135, 88);
		will-change: transform;
		@include transform(translate(-$p2,-$p2) translateZ(0px) scale(1));
		opacity: 1;
		top: 0;
		left: 0;
		@include flex;
		align-content: center;
		overflow: hidden;
		justify-content: center;
		will-change: opacity,transform,width,border-radius,height;
		@include transition(opacity 0.15s ease-in-out, transform 0.25s ease-in-out,width 0.25s ease-in-out,border-radius 0.25s ease-in-out,height 0.25s ease-in-out,background 0.15s ease-in-out);
		&.typing {
			border-radius: 14px;
			width: 100px;
			height: 24px;
			.typing {
				opacity:1;
			}
		}
		.typing {
			position: absolute;
			color: white;
			font-size: 15px;
			line-height: 24px;
			white-space: nowrap;
			opacity:0;
			will-change: opacity;
			@include transition(opacity .25s ease-in-out);
		}
	}
	.trail {
		z-index: 9999;
		display: block;
		position: fixed;
		border-top-left-radius: $p2;
		border-top-right-radius: $p2;
		border-bottom-right-radius: $p2;
		border-bottom-left-radius: $p2;
		pointer-events: none;
		width: 216px;
		height: 216px;
		background-color: rgba(20, 135, 88, 0.2);
		will-change: opacity,transform;
		@include transition(opacity 0.3s ease-in-out, transform 0.3s ease-in-out,background 0.3s ease-in-out);
		will-change: transform;
		@include transform(translate(-$p2,-$p2) translateZ(0px) scale(.08));
		opacity: 1;
		top: 0;
		left: 0;
	}
	&.hover {
		.trail {
			@include transform(translate(-$p2,-$p2) translateZ(0px) scale(1));
		}
	}
	&.switch-hover {
		.follow {
			background-color: rgba(0, 0, 0, 0.3);
		}
		.trail {
			@include transform(translate(-$p2,-$p2) translateZ(0px) scale(1));
			background-color: rgba(0, 0, 0, 0.1);
		}
	}
}

// preloader
.preloader {
	position: fixed;
	height:100vh;
	width:100vw;
	top:0;
	left:0;
	background:white;
	z-index:99999;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	align-items:flex-end;
	justify-content: flex-end;
	will-change: opacity,visibility;
	@include transition(opacity 1s .6s ease,visibility 1s .6s ease);
	opacity:1;
	visibility: visible;
	strong {
		font-size:33vw;
		@include tablet {
			position: relative;
			top:-15vh;		
		}
		span {
			display:inline-block;
			will-change: opacity,transform;
			@include transition(opacity 1s ease,transform 1s ease);
		}
	}
	&.loaded {
		opacity: 0;
		visibility: hidden;
		strong {
			span {
				opacity:0;
				transform:translateY(60px);
			}
		}
	}
}

// icons
.icon-start {
	position: relative;
	&:after {
		position: absolute;
		right:0;
		top:$p2;
	}
}


#thanks .ticker {
	position: relative;
	z-index:2;
}

.meme-container {
	position: relative;
	padding-top:20px;
	padding-bottom:20px;
	padding-left:25%;
	@include mid {
		grid-template-columns: 1fr;		
	}
	.intro-text {
		width:80%;
		@include tablet {
			width:$p1;		
		}
		a {
			&.margin-right {
				margin-right:24px;
			}
		}
	}
}

.meme {
	position: relative;
	z-index:0;
	width:$p1;
	@include mid {
		display:none;		
	}
	img {
		width:$p1;
		max-width:400px;
		margin:-40% auto 0;
		display:block;
	}
}




// modal
.popup-content {
	width:92%!important;
	max-width:700px;
	height:92vh;
	overflow:auto;
}
.popup-overlay {
	z-index:999!important;
}
.modal {
	font-size: 16px;
	padding:20px;
}
.modal > .header {
	width: 100%;
	border-bottom: 3px solid var(--green);
	font-size: 24px;
	text-align: center;
	padding: 5px 5px 10px;
	color:var(--green);
	text-transform: uppercase;
}
.modal > .content {
	width: 100%;
	padding: 14px 10px;
	p {
		line-height:1.5;
		color:var(--blue);
	}
}
.modal > .actions {
	width: 100%;
	padding: 10px 5px;
	margin: auto;
	text-align: center;
}
.modal > .close {
	cursor: pointer;
	position: absolute;
	display: block;
	padding: 2px 5px;
	line-height: 20px;
	right: 0;
	top: 0;
	background:transparent;
	font-size: 36px;
	border: 0;
	outline: 0 !important;
	margin: 10px;
	color:var(--green);
	font-family:'IBM Plex Sans', sans-serif;
}



.contact-list {
	display:grid;
	grid-template-columns: 1fr 1fr;
}
