
.magnet-link {
	opacity:1;
	@include transform(translateY(0));
	will-change: opacity,transform;
	&.down {
		opacity:0;
		@include transform(translateY(25%));
		@include transition(opacity .1s $easeOutQuart,transform .1s $easeOutQuart);
	}
	&.up {
		opacity:0;
		@include transform(translateY(-25%));
		@include transition(opacity .1s $easeInQuart,transform .1s $easeInQuart);
	}
}
a.magnet {
	display:inline-block;
	position: relative;
	overflow: hidden;
	padding-bottom:0px;
	line-height:1.5;
	&.noanimation {
		line-height:1;
		overflow: auto;
	}
	&.more-top {
		margin-top:30px;
	}
	&.hide-mag {
		display:none!important;
	}
	@include midtab {
		@include transform(none!important);			
	}
	&:after {
		content:'';
		position: absolute;
		top:calc(100% - 2px);
		left:$p2;
		width:0;
		height:2px;
		background:currentColor;
		transform:translateX(-$p2);
		will-change: width;
		@include transition(width .3s ease);
	}
	> span {
		display:inline-block;
		will-change: opacity,transform;
		@include transition(opacity .3s ease,transform .3s ease);
		&.ml-in {
			opacity:1;
			transform:translateY(0%);
		}
		&.ml-out {
			position: absolute;
			top:$p2;
			left:$p2;
			width:$p1;
			opacity:0;
			transform:translate(-$p2,$p1);
		}
		span {
			@include transform(uppercase);
			font-weight:400;
		}
		svg {
			margin-left:14px;
			width:21px;
			height:21px;
			@include tablet {
				width:17px;
				height:17px;		
			}
		}
	}
	&.ml-hover {
		&:after {
			width:$p1;
		}
	}
	&.ml-hover:not(.noanimation) {
		span {
			&.ml-in {
				opacity:0;
				transform:translateY(-$p1);
			}
			&.ml-out {
				position: absolute;
				top:$p2;
				left:$p2;
				width:$p1;
				opacity:1;
				transform:translate(-$p2,-$p2);
			}
		}
	}
	&.magnet-icon {
		padding-right:2px;
	}
	&.noanimation {
		display:inline;
	}
}


a.magnet-ball {
	position: fixed;
	// overflow: hidden;
	padding-bottom:0px;
	z-index:99;
	bottom:0;
	right:0;
	margin:42px;
	width: 42px;
	height:42px;
	background:var(--green);
	border-radius: $p1;
	@include flex;
	justify-content: center;
	align-items: center;
	will-change: width,height;
	@include transition(width .3s,height .3s);
	@include tablet {
		@include transform(none!important);
		height:32px!important;
		width:32px!important;	
		margin:32px 0;
		right:auto;
		left:18px;
		overflow:unset;
	}
	&.about {
		// display:none;
	}
	p {
		color:$w;
		font-weight:bold;
		opacity: 0;
		will-change: opacity,transform;
		@include transform(scale(.8));
		@include transition(opacity .3s,transform .3s);
		@include tablet {
			position: absolute;
			left:$p1;
			margin-left:10px;
			top:$p2;
			color:var(--green);
			opacity:1;	
			font-weight:normal;
			@include transform(translateY(-$p2));
			background: var(--white);
			padding: 2px 10px;
			border-radius: 20px;
			display:none;
		}
	}
	svg {
		position:absolute;
		top:$p2;
		left:$p2;
		@include transform(translate(-$p2,-$p2));
		@include transition(opacity .3s .3s);
		circle {
			stroke:rgba(0,0,0,0);
			fill:transparent;
			@include transform(rotate(-90deg));
		  	transform-origin: 50% 50%; 
		  	stroke-linecap: round;
		}
		circle.progress {
			stroke:var(--ballline);
			@include transform(rotate(-90deg));
		  	transform-origin: 50% 50%;
		  	@include transition(stroke-dashoffset 0.35s);
		  	stroke-linecap: butt;
		}
	}
	&.ml-hover {
		width:180px;
		height: 180px;
		p {
			opacity:1;
			@include transform(scale(1));
			@include transition(opacity .3s .15s,transform .3s .15s);
			@include tablet {
				@include transform(translateY(-$p2));		
			}
		}
		svg {
			opacity:0;
			@include transition(opacity .1s);
			@include tablet {
				opacity:1;		
			}
		}
	}
	&.magnet-icon {
		padding-right:2px;
	}
}