.lettering {
	br {
		@include tablet {
			display:none;		
		}
	}
	span.ch {
		display:inline-block;
		text-indent: 0;
		opacity:0;
		will-change: opacity,transform;
		@include transform(translateY($p2));
		&.indent {
			text-indent:120px;
			@include tablet {
				text-indent: 0;		
			}
		}
	}
	span.ch-space {
		width:5vw;
	}
	&.down {
		span.ch {
			opacity:0;
			@include transform(translateY($p2));
		}
	}
	&.up {
		span.ch {
			opacity:0;
			@include transform(translateY(-$p2));
			@include transition(opacity .1s $easeInQuart,transform .1s $easeInQuart);
		}
	}
	&.in {
		span.ch {
			opacity:1;
			@include transform(translateY(0));
			@include transition(opacity .1s $easeOutQuart,transform .1s $easeOutQuart);
		}
	}
	&.half {
		span.ch {
			@include transform(translateY(0));
		}
		&.down {
			span.ch {				
				@include transform(translateY(25%));
			}
		}
		&.up {
			span.ch {				
				@include transform(translateY(-25%));
			}
		}	
	}
}
// parent case
[status="down"] {
	.lettering {
		span.ch {
			opacity:0;
			@include transform(translateY($p2));
		}
	}
}
[status="up"] {
	.lettering {
		span.ch {
			opacity:0;
			@include transform(translateY(-$p2));
			@include transition(opacity .1s $easeInQuart,transform .1s $easeInQuart);
		}
	}
}
[status="in"] {
	.lettering {
		span.ch {
			opacity:1;
			@include transform(translateY(0));
			@include transition(opacity .1s $easeOutQuart,transform .1s $easeOutQuart);
		}
	}
}