// color
$w:white;
$b:black;

// sizes
$p1:100%;
$p2:50%;

// ease
$easeInQuart:cubic-bezier(0.5, 0, 0.75, 0);
$easeOutQuart:cubic-bezier(0.25, 1, 0.5, 1);
$easeInOutQuart:cubic-bezier(0.76, 0, 0.24, 1);


:root {
	--black:black;
	--white:white;
	--blue:#0B2A5C;
	--green:#148758;
	--grey:#656565;
	--dark:#141414;
	--ballline:#CCE67D;
}
[data-invert="one"]{
	--white:#FFECB4;
	--blue:#0F4A3A;
	--green:#7FB2ED;
	--ballline:#A257C2;
}
[data-invert="two"]{
	--white:#FEA483;
	--blue:#184573;
	--green:#A257C2;
	--ballline:#7FB2ED;
}
[data-invert="three"]{
	--white:#262626;
	--blue:#F9F9F9;
	--green:#CCE67D;
	--ballline:#148758;
}